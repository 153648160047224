import React, { Fragment } from 'react';
import ActivityTabs from '../components/ActivityTabs'

export default (props: RouterPropsType) => (
  <ActivityTabs
    activityType="DISCOUNT"
    history={props.history}
    activityTabName="折扣活动"
    productTabName="折扣商品"
  />);
